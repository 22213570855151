import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Stack,
  FormControl,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import CssTextField from "../../components/common/CssTextField.jsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import StyledDatePicker from "../../components/common/StyledDatePicker.jsx";
import CssMultiTextField from "../../components/common/CssMultiTextField.jsx";
import MenuItem from "@mui/material/MenuItem";
import StyledButton from "../../components/common/StyledButton";
import StyledLoadingButton from "../../components/common/StyledLoadingButton";
import MainCard from "../../components/common/MainCard.jsx";
import "../../sassStyle/global.scss";
import "./AddExpenseEntry.scss";
import expenseEntryService from "../../GraphQL/Services.js";
import { LOAD_EXPENSE_CODE, LOAD_RATES } from "../../GraphQL/Queries";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { literals } from "../../enums/literalCodes.js";
import Upload from "../../components/common/Upload";

const INITIALSTATE = {
  clientMatter: "",
  entryDate: dayjs(new Date()).format("YYYY-MM-DD"),
  date: dayjs(new Date()),
  activityId: 66000,
  ///
  id: -1,
  ts: null,
  modifiedById: 2,
  modifiedDate: null,
  invoiceId: null,
  description: "",
  note: "",
  budgetPhaseId: null,
  employeeId: 104, //5894
  quantity: 0,
  amount: parseFloat(0).toFixed(2),
  rate: parseFloat(0).toFixed(2),
  expenseCodeId: "",
  locationId: "",
  doNotCharge: false,
  writeOff: false,
  customerId: 56,
  billable: null,
  activityTypeId: "",
  activityDate: "2023-07-28",
  isAdjusted: false,
  adjustedDate: "2023-08-02",
};

const AddExpenseEntry = ({GetExpenseEntryList, isOpenFromModel, selectedExpenseEntry, handleClose }) => {
  const [state, setState] = useState(isOpenFromModel ? selectedExpenseEntry : INITIALSTATE);
  const [errors, setErrors] = useState({});
  const [shouldFetch, setShouldFetch] = useState(true);
  const { error, loading, data } = useQuery(LOAD_EXPENSE_CODE);
  const [allExpenseCodes, setAllExpenseCodes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allActivityTypes, setAllActivityTypes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { error : ratesError, loading : ratesLoading, data : ratesData } = useQuery(LOAD_RATES, {
    skip : shouldFetch,
    variables: {id : Number(state.expenseCodeId)}
  });

  const handleDate = (date) => {
    //Converting date to the YYYY-MM-DD format as required by the API.
    setState({
      ...state,
      entryDate: date !== null && date.format("YYYY-MM-DD"),
      date: date,
    });
    setErrors({ ...errors, date: "" });
  };

  const handleChange = (event) => {

    const { name, value } = event.target;
    setState({ ...state, [name]: value });

    // Clear the corresponding error state when the input value changes
    setErrors({ ...errors, [name]: '' });
  }

  const handleActivityType = (event) => {
    const { name, value } = event.target;
    if(event.target.value == 103) {
      setState({ 
        ...state, 
        [name]: value
      });
      setIsDisabled(false)
    }
    else {
      setState({ 
        ...state, 
        [name]: value,
        rate: ratesData.getRateByExpenseCode
      });
      setIsDisabled(true)
    }
  }

  const handleExpenseCode = (event) => {
    const { name, value } = event.target;
      setState({ 
        ...state, 
        [name]: value
      });
  }

  const resetForm = () => {
    setState({
      ...INITIALSTATE,
      expenseCodeId: data.getAllExpenseCodes[0].id,
      locationId: data.getAllGeoLocations[0].id,
      activityTypeId: data.getAllActivityType[0].id
    });
  }

  const addExpense = () => {
    expenseEntryService.addExpenseRequest({
      id: state.id,
      ts: state.ts,
      modifiedById: state.modifiedById,
      modifiedDate: state.modifiedDate,
      rate: Number(state.rate),
      amount: Number(state.amount),
      activityTypeId: Number(state.activityTypeId),
      activityDate: state.activityDate,
      isAdjusted: state.isAdjusted,
      adjustedDate: state.adjustedDate,
      employeeId: state.employeeId,
      customerId: state.customerId,
      billable: state.billable,
      quantity: Number(state.quantity),
      expenseCodeId: Number(state.expenseCodeId),
      geoLocationId: Number(state.locationId),
      description: state.description,
      note: state.note,
      invoiceId: state.invoiceId,
      budgetPhaseId: state.budgetPhaseId,
      doNotCharge: state.doNotCharge,
      writeOff: state.writeOff,
    }).then(()=> GetExpenseEntryList());
  }
  useEffect(() => {
    if(data){
      setAllExpenseCodes(data.getAllExpenseCodes)
      setAllLocations(data.getAllGeoLocations)
      setAllActivityTypes(data.getAllActivityType)
      setState({
        ...state,
        expenseCodeId: data.getAllExpenseCodes[0].id,
        locationId: data.getAllGeoLocations[0].id,
        activityTypeId: data.getAllActivityType[0].id
      })
      if(data.getAllExpenseCodes[0].id){
        setShouldFetch(false)
      }
    }
  }, [data]);

  useEffect(() => {
    if(ratesData){
      setState({
        ...state,
        rate : ratesData.getRateByExpenseCode
      })
    }
  }, [ratesData])
  
  const onDiscardModal = (ModalType) => {
    if(isOpenFromModel){
      handleClose()
    }
  }

  const handleQuantityChange = (event) => {
    let updatedQuantity = event.target.value;
    if(event.target.value < 1) updatedQuantity = INITIALSTATE.quantity;
      setState({
        ...state,
        quantity:parseInt(updatedQuantity),
        amount: parseFloat(state.rate * updatedQuantity).toFixed(2),
    });
  }

  const GetSecondaryComponents = () => {
    return (
        <div className="secondarytimer">
          <div>
              <Tooltip>
                <IconButton onClick={() => onDiscardModal()}>
                  <CloseIcon style={{ color: '#0050C3' }} />
                </IconButton>
              </Tooltip>
          </div>
        </div>
    )
  }

   //Custom Datepicker calendar Icon to add Tooltip to. 
   const getCalendarIcon = () =>{
    return (
        <Tooltip title="Open Calandar">
            <DateRangeIcon />
        </Tooltip>
    )
  }

  const uploadFile = (file) => {
    setState({ ...state, file });
  };

  const removeUpload = () => {
    setState({ ...state, file: null });
  };

  return (
    <>
      <form style={{ marginLeft: "100px", marginTop: "20px" }}>
        <MainCard
          className="time-entry-main-card"
          title={literals.EXPENSE_ENTRY}
          secondary={GetSecondaryComponents()}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CssTextField
                label="Client/Matter"
                required
                size="small"
                fullWidth
                autoComplete="off"
                name="clientMatter"
                // value={state.clientMatter}
                // onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                color="primary"
                // error={errors.clientMatter}
                // helperText={errors.clientMatter}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Type"
                fullWidth
                color="primary"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="activityTypeId"
                value={state.activityTypeId}
                select
                onChange={handleActivityType}
              >
                {allActivityTypes?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data.id}>
                      {data.code}
                    </MenuItem>
                  );
                })} 
              </CssTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledDatePicker
                    label="Date"
                    components={{
                        OpenPickerIcon: getCalendarIcon
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        // error: !!errors.date,
                        // helperText: errors.date,
                        InputLabelProps: { shrink: true },
                        slots: { OpenPickerIcon: <StopCircleIcon /> },
                        className: "custom-datepicker-input",
                      },
                    }}
                    value={state.date}
                    onChange={(date) => handleDate(date)}
                    name="entryDate"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    inputFormat="MM/DD/YYYY"
                    color="primary"
                    format="MM/DD/YYYY"
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <CssTextField
                label="Timekeeper"
                fullWidth
                name="timeKeeper"
                // onChange={handleChange}
                size="small"
                color="primary"
                // value={state.timeKeeper}
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <CssTextField
                label="Location"
                fullWidth
                color="primary"
                InputLabelProps={{ shrink: true }}
                size="small"
                name="locationId"
                value={state.locationId}
                select
                // autoComplete="off"
                onChange={handleChange}

                // error={!!errors.location}
                // helperText={errors.location}
              >
                {allLocations?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data.id}>
                      {data.code} - {data.description}
                    </MenuItem>
                  );
                })}
              </CssTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CssTextField
                label="Expense"
                fullWidth
                name="expenseCodeId"
                onChange={handleExpenseCode}
                select
                size="small"
                color="primary"
                value={state.expenseCodeId}
                // autoComplete="off"
                InputLabelProps={{ shrink: true }}
              >
                {allExpenseCodes?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data.id}>
                      {data.code} - {data.description}
                    </MenuItem>
                  );
                })}
              </CssTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Quantity"
                fullWidth
                InputLabelProps={{ shrink: true }}
                color="primary"
                size="small"
                autoComplete="off"
                name="quantity"
                value={state.quantity}
                onChange={(quantity) => handleQuantityChange(quantity)}
                // error={!!errors.hours}
                // helperText={errors.hours}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Rate"
                fullWidth
                color="primary"
                name="rate"
                autoComplete="off"
                disabled={isDisabled}
                value={state.rate}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                    ),
                    sx: {
                        background: "red"
                    },
                }}
                // error={!!errors.amount}
                // helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Amount"
                fullWidth
                color="primary"
                name="amount"
                autoComplete="off"
                disabled={true}
                value={state.amount}
                InputLabelProps={{ shrink: true }}
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            $
                        </InputAdornment>
                    ),
                    sx: {
                        background: "red"
                    },
                }}
                // error={!!errors.amount}
                // helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <CssMultiTextField
                label="Narrative"
                fullWidth
                color="primary"
                name="description"
                autoComplete="off"
                value={state.description}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                multiline
                rows={3}
                // error={!!errors.narrative}
                // helperText={errors.narrative}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <CssMultiTextField
                label="Notes"
                fullWidth
                color="primary"
                InputLabelProps={{ shrink: true }}
                name="note"
                size="small"
                autoComplete="off"
                onChange={handleChange}
                value={state.note}
                multiline
                rows={2}
                // error={!!errors.note}
                // helperText={errors.note}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <div className="view-more-button">
                <Stack>
                  {
                    // if there is some entry selected than remove cancel button
                    // !isOpenFromModel &&
                    <StyledButton
                     onClick={resetForm}
                    >
                      {literals.CLEAR_ENTRY}
                    </StyledButton>
                  }
                </Stack>
                <Stack>
                    <Upload onInputChange={uploadFile} file={state.file} removeUpload={removeUpload}/>
                </Stack>
                <Stack>
                  <StyledLoadingButton
                    // disabled={isTracking}
                    // onClick={() => onSubmit(literals.SAVE)}
                    onClick={addExpense}
                    // id={literals.SAVE} loading={isSaving}
                    variant="outlined"
                  >
                    {/* {literals.SAVE} */}
                    Save
                  </StyledLoadingButton>
                  <StyledLoadingButton
                    // disabled={isTracking}
                    // onClick={addExpense}
                    // id={literals.POST} loading={isPosting}
                    variant="contained"
                  >
                    {/* {literals.POST} */}
                    Post
                  </StyledLoadingButton>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </MainCard>
      </form>
    </>
  );
};

export default AddExpenseEntry;
