import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import StyledButton from "./StyledButton"
import { literals } from '../../enums/literalCodes';
import "./ConfirmationDialogDetailed.scss"

const AlertDialog = ({ open = false, title, message, cancelLabel, discardLabel, confirmLabel, onCancel, onConfirm, onDiscard, isProcessing = false, ModalType, rowId = "" }) => {
return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            border: '3px solid rgba(0, 80, 195, 0.12)',
            borderRadius: '10px',
            boxSizing: 'border-box', 
          },
       }}
      >
        <DialogTitle className="dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <div className={`${ModalType === literals.THREE_ACTIONS ? 'dialog-between-button-container' : 'dialog-right-button-container'}`}>
            <StyledButton style={{color: "#42A5F5"}} onClick={onCancel}>
              {cancelLabel}
            </StyledButton>
            <div className='dialog-button-group'>
              <StyledButton variant={ModalType === literals.THREE_ACTIONS ? "outlined" : "text"} style={{color: "#0050C3"}} onClick={()=>onDiscard(rowId ? rowId : ModalType)}>
                {discardLabel}
              </StyledButton>
              {ModalType === literals.THREE_ACTIONS && <StyledButton variant="contained" onClick={()=>onConfirm(ModalType)} autoFocus>
                {confirmLabel}
              </StyledButton>}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;