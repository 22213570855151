import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const StyledButton = styled(Button)`
  text-transform: none;
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 36px;
  border-radius: 4px;
`;

export default StyledButton;
