import { gql } from "@apollo/client";

export const LOAD_EXPENSE_CODE = gql`
  query {
    getAllExpenseCodes {
      id
      code
      description
    }
    getAllGeoLocations {
      id
      code
      description
    }
    getAllActivityType {
      id
      code
    }
  }
`;

export const LOAD_RATES = gql`
  query getRateByExpenseCode($id: Int!) {
    getRateByExpenseCode(input: { 
      expenseCodeId: $id,
      customerId: 56,
      entryDate: "2023-08-11"
    })
  }
`;

export const LOAD_ALL_ACTIVITY_EXPENSE = gql`
  query {
    getAllActivityExpenses {
      id
      activity {
        quantity
        rate
        amount
      }
      clientEntity {
        id
        code
        description
      }        
      customerEntity {
        id
        code
        description
      }            
      activityExpense {
        activityID
        expenseCode {
          id
          code
          description
        }
        invoiceID
        description
        note
        budgetJobPhaseID
      }
      activityStatus {
        id
        code
      }
    }
  }
`;
