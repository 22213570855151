import expenseRequestMutation from "./Mutations";

const expenseEntryService = {
  addExpenseRequest: async (input) => {
    return await expenseRequestMutation.insertExpense(input);
  },
  deleteExpenseEntry: async(expenseEntryId) => {
    return await expenseRequestMutation.deleteExpenseEntry(expenseEntryId);
  },
};

export default expenseEntryService;
