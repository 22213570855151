import ExpenseEntryContainer from "./pages/ExpenseEntry/index";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

//window.localStorage.setItem("sid", "147E2AD727C938699CE3E3458AE1033BE59AECE9D28A85046075F5F16E76CE66" )

const httpLink = createHttpLink({
  uri: "https://apiv2.dev-auth.surepoint.cloud/expense-management/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("sid");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      sid: token,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
        <ExpenseEntryContainer />
    </ApolloProvider>
  );
}