import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://apiv2.dev-auth.surepoint.cloud/expense-management/graphql",
  headers: {
    "Content-Type": "application/json",
    sid: localStorage.getItem("sid"),
  },
});

const expenseRequestMutation = {
  insertExpense: async (input) => {
    const mutation = `
    mutation ($ActivityExpenseSaveDraftInput: ActivityExpenseSaveDraftInput!) {
      saveActivityExpenseDraft(input: $ActivityExpenseSaveDraftInput) {
        id
        ts
        modifiedById
        modifiedDate
        rate
        amount
      }
    }
    `;
    
    const response = await axiosInstance.post("", {
      query: mutation,
      variables: { ActivityExpenseSaveDraftInput: input },
    });

    return response.data;
  },

  deleteExpenseEntry : async (entryId) => {
    const mutation = `
    mutation deleteActivityExpense {
      deleteActivityExpense(input: {
        id: ${entryId}
        ts: null,
        modifiedById: 2,
        modifiedDate: null,
        invoiceId: null
      })
    }
  `;  

    const response = await axiosInstance.post('', {
      query: mutation,
      variables: { deleteActivityExpense : entryId },
    });

    return response.data;
  },
};

export default expenseRequestMutation;
