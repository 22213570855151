import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { styled } from "@mui/material/styles";

const StyledDatePicker = styled(DesktopDatePicker)({
  "& label.Mui-focused": {
    color: "#1976d2",
    top: 0,
  },
  "& span.MuiInputLabel-asterisk": {
    color: "#FF9800",
  },
  "& .MuiInputLabel-root": {
    color: "#1976d2",

    // top: '-9px',
    // borderRadius: '8px',
    // backgroundColor: '#fff',
    // '& fieldset': {
    //   borderRadius: '8px',
    //   borderColor: '#1976d2',
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#1976d2',
    //   borderWidth: '1px',
    // },
  },
  "& .MuiFormControl-root .MuiTextField-root": {
    // top: '-9px',
    width: "100%",
  },
  "& .MuiIconButton-root": {
    color: "#1976D2",
  },
  "& .MuiOutlinedInput-root": {
    height: "41px",
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: "8px",
      borderColor: "#1976d2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976d2",
      borderWidth: "1px",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "#fff",
    },
  },
  "& .MuiInputBase-root": {
    letterSpacing: "-0.65px",
  },
});

export default StyledDatePicker;
