
import React, { useState } from "react";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';

function Upload({
  name = "",
  onInputChange,
  file = null,
  removeUpload
}) {

  const onChangeEvent = (e) => {
    if(onInputChange){
      onInputChange(e.target?.files[0]);
    }
  };

  const handleCancelClick = () => {
    if(removeUpload){
      removeUpload();
    }
  }

  const uploadBoxStyle={display:'flex', alignItems: 'center', padding: '5px', borderRadius: '4px', border: '1px solid #1976D280', cursor: 'pointer'};
  const fileStyle={display:'flex', alignItems: 'center', padding: '4px', borderRadius: '4px', backgroundColor: '#0050C3', cursor: 'pointer', color: '#fff'};
  const uploadLabel={marginLeft: '6px', color: '#0050C3', fontSize: '13px', fontWeight: 500};
  const fileIcons = {fontSize: '18px', color: "#ffffff"};
  const uploadIcon = {fontSize: '18px', color:"#1976D2"};

  return (
    <div style={{marginTop: '6px', marginLeft: '2rem'}}>
      { file ? <div style={{...fileStyle, cursor: 'text'}}>
        <PictureAsPdfIcon sx={fileIcons} />

        <div style={{...uploadLabel, color: 'white', marginRight: '0.4rem', marginLeft: '0.5rem'}}>{file?.name}</div>
        <CloseIcon sx={{...fileIcons, cursor: 'pointer'}} onClick={handleCancelClick} />
      </div> 
      : 
      <div>
        <input type="file" id="files" name={name} accept="application/pdf" onChange={onChangeEvent} hidden />
        <label for="files" id="lable_file" style={uploadBoxStyle}>
            <UploadFileIcon sx={uploadIcon}/>
            <span className="uploadText" style={{...uploadLabel, paddingRight: '7px',fontFamily: 'Roboto'}} >Upload Attachment</span>
        </label>
      </div>}
    </div>
  );
}

export default Upload;
