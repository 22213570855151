import { Box, Pagination} from "@mui/material";
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import React from 'react';
import '../../pages/ExpenseEntry/ExpenseEntryDataGrid.scss';

export default function CustomPaginationExpenseEntryGrid() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (<Box sx={{width:"100%", textAlign:"center"}}>
       <Pagination
        style={{display:"inline-block",padding:"20px"}}
        color="primary"
        // className="timeEntryGridContainer"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
      </Box>
    );
  }
