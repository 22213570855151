import PropTypes from "prop-types";
import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          // border: border ? '1px solid' : 'none',
          // borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary[200] + 75,
          // color : "red",
          // ':hover': {
          //     boxShadow: boxShadow
          //         ? shadow ||
          //           (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
          //         : 'inherit'
          // },
          ...sx,
        }}
      >
        {/* card header and action */}
        {/* {title && <CardHeader sx={{backgroundColor: "white", color: "#f0a830"}}  title={title} action={secondary} />} */}
        {title && (
          <CardHeader
            sx={{ backgroundColor: "white" }}
            title={<Typography style={styleTypography}>{title}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {/* {title && <Divider />} */}

        {/* {button && <Button variant="contained" color="secondary" >File a BSR</Button>} */}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

const styleTypography = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.14000000059604645px",
};

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  button: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default MainCard;
