export const literals = {
    EMAIL: 'Email',
    CANCEL: 'Cancel',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONTINUE: 'Continue',
    FORGOT_PASSWORD: 'Forgot Password',
    NEW_PASSOWRD: 'New Password',
    PASSWORD: 'Password',
    REMEMBER_ME: 'Remember Me',
    RESEND_CODE: 'Resend code',
    SIGN_IN: 'Sign In',
    SUCCESS: 'success',
    ERROR: 'error',
    CODE: 'Code',
    SAVE: 'Save',
    POST: 'Post',
    MORE_FIELDS: 'More Fields',
    LESS_FIELDS: 'Less Fields',
    MY_DASHBOARD : "My Dashboard",
    DASHBOARD_DESCRIPTION: "Add a description",
    INCORRECT_USERNAME_OR_PASSWORD: 'Email and/or password is incorrect.',
    TOO_MANY_SIGN_ON_ATTEMPTS: 'Too many failed sign in attempts. You can try again in 15 minutes.',
    MISSING_SIGN_ON_LINK: 'Your session could not be validated. Refresh the page and try signing in again. If this error keeps happening, contact your system admin.',
    MISSING_FORGOT_PASSWORD_LINK: 'Oops. Something went wrong. Go back and try to reset your password again.',
    AN_UNEXPECTED_ERROR_HAS_OCCURRED: 'An unexpected error has occurred. Please try again later.',
    FORGOT_PASSWORD_HEADER_LABEL: 'Enter your email below to receive your authentication code.',
    EMAIL_NOT_FOUND: 'That email address was not found. Double-check the email address or contact your system admin for help.',
    INVALID_EMAIL_FORMAT: 'Enter in the format: name@example.com.',
    MISSING_RESEND_CODE_LINK: 'Oops. Something went wrong. Go back and request a new code.',
    AUTHENTICATION_CODE_SENT: 'Code sent.',
    UNABLE_TO_RESEND_CODE: 'The code could not be resent. Try again in a few minutes. If this error keeps happening, contact your system admin.',
    INVALID_AUTHENTICATION_CODE: 'That code may be wrong or expired. Double-check the code and try again, or request a new code.',
    PASSWORD_CANNOT_BE_SAME_AS_YOUR_LAST_4_USED_PASSWORDS: 'Password cannot be the same as your last 4 used passwords.',
    PASSWORD_CANNOT_CONTAIN_INFO_IN_PROFILE: 'Password cannot contain your name or personal information.',
    PASSWORD_CANNOT_BE_SIMILAR_CURRENT_PASSWORD: 'Password cannot be so similar to your current password.',
    PASSWORD_MUST_NOT_BE_COMMON_USED: 'Password cannot contain words that we consider \'common\' or easy to guess.',
    PASSWORD_POLICY: 'Password must be at least 8 characters long with at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.',
    PASSWORD_CHANGED: 'Password changed.',
    MISSING_RESET_PASSWORD_LINK: 'Oops. Something went wrong. Go back and try to reset your password again.',
    RESET_PASSWORD_HEADER_LABEL: 'Enter the code from your inbox and your new password.',
    PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH: 'Password and confirmation do not match.',
    STATUS_NOT_COMPLETED: 'Status is not completed',
    NEXTGEN_ICON_LABEL: 'SurePoint Cloud',
    LMS_ICON_LABEL: 'LMS+',
    LMS_4_5_ICON_LABEL: 'LMS IV/V',
    REMEMBER_MY_CHOICE: 'Remember my choice',
    USER_MANAGEMENT: 'User management',
    SAVE_CHECKED_AGAINST_DELETED_USER_TABLE: 'Matching Deleted User Found - A matching user already exists but has been deleted.  Create a unique user or use the Restore Deleted Users option to restore this user',
    INVALID_PHONE_NUMBER: 'Enter in a 10-digit format: 1234567890 or 123-456-7890.',
    SAVE_MESSAGE_SUCCESSFULL: 'Profile settings saved.',
    MANAGE_YOUR_TEAM_MEMBERS_HERE: 'Manage your team members here',
    EDIT_USER: 'Edit user',
    NEW_USER: 'New user',
    ADD_NEW_USER_INFORMATION_BELOW: 'Add new user information below',
    EDIT_NEW_USER_INFORMATION_BELOW: 'Edit user information below',
    NAME: 'Name',
    FIRST: 'First',
    MIDDLE: 'Middle',
    LAST: 'Last',
    SUFFIX: 'Suffix',
    EMAIL_CANNOT_BE_CHANGED: 'You cannot change your email address.',
    PHONE_NUMBER: 'Phone Number',
    CLEAR_REGISTERED_MFA_DEVICE: 'Remove Registered MFA Methods',
    ADD_REGISTERED_MFA_DEVICE: 'Add Registered MFA Methods',
    SAVE: 'Save',
    PROFILE_SETTINGS: 'Profile settings',
    CUSTOMIZE_YOUR_PROFILE_SETTINGS_HERE: 'Customize your profile settings here',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    RESET_PASSWORD: 'Reset password',
    CHANGE_YOUR_PASSWORD_HERE: 'Change your password here',
    COYOTE_ANALYTICS: 'Coyote Analytics',
    MFA_CODE: 'MFA Code',
    AUTHENTICATION_METHODS_ADDED_HEADER_LABEL: 'Selection an authentication method to use to sign in or add another method.',
    AUTHENTICATION_THROUGH_APPQR: 'Authenticator App',
    TEXT_MESSAGE: 'Text Message',
    ADD_ANOTHER_AUTHENTICATION_METHOD: 'Add Another Authentication Method',
    ENTER_YOUR_EMAIL_FOR_AUTHENTICATION: 'Enter your email for authentication.',
    ENTER_YOUR_PHONE_NUMBER_FOR_AUTHENTICATION: 'Enter your phone number for authentication.',
    AUTHENTICATION_THROUGH_APPQR_HEADER_LABEL: 'Scan the QR code using your camera or authenticator app, or use the provided key to register your authenticator app.',
    SELECT_MFA_HEADER_LABEL: 'Select the authentication method you want to add.',
    USE_AUTHENTICATION_THROUGH_APPQR_INFO: 'Use an authenticator app on your phone, such as Microsoft Authenticator or Google Authenticator, to authenticate.',
    RECEIVE_TEXT_MESSAGE_INFO: 'Receive a text message with a passcode to authenticate.',
    RECEIVE_EMAIL_WITH_PASSCODE_INFO: 'Receive an email with a passcode to authenticate.',
    ENTER_THE_CODE_RECEIVED_TO_COMPLETE_SMS_PAIRING: 'Enter the code you received to complete SMS pairing.',
    ENTER_THE_MFA_CODE_SENT_TO_EMAIL: 'Enter the MFA code sent to your email to sign in.',
    KEY: 'KEY',
    TRY_ANOTHER_METHOD: 'Try another method',
    MANAGE_MFA_METHODS: 'Manage MFA Methods',
    REMOVE_MFA_METHOD: 'Remove MFA Method?',
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_SELECTED_MFA_METHOD: 'Are you sure you want to remove the selected MFA method?',
    GO_BACK: 'Go Back',
    REMOVE: 'Remove',
    INVALID_DATA: 'Username and/or password is incorrect.',
    CURRENT_PASSWORD_IS_INCORRECT: 'Current password is incorrect.',
    MFA_CODE_INVALID_OR_EXPIRED: 'Code is invalid or expired. Request a new code.',
    MFA_METHOD_HAS_BEEN_REMOVED: 'MFA method has been removed.',
    AUTHENTICATOR_APP_ADDED: 'Authentication app added.',
    SMS_AUTHENTICATION_ADDED: 'Text message authentication added.',
    EMAIL_AUTHENTICATION_APP_ADDED: 'Email Authentication is added.',
  
    // ExpenseEntry
    EXPENSE_ENTRY: 'Expense Entry',
    EXPENSE_ENTRY_GRID: 'Expense Entry Grid',
    THIS_IS_A_REQUIRED_FIELD: 'this is a required field',
    A_CLIENT_MATTER_MUST_BE_PROVIDED: 'A client matter must be provided',
    HOURS_MUST_BE_PROVIDED: 'Hours must be provided.',
    A_NARRATIVE_MUST_BE_PROVIDED: 'A narrative must be provided.',
    POSTED : 'Posted',
    DRAFT : 'Draft',
    DRAFT_READY_TO_POST : 'Draft - Ready to post',
    DASHBOARD_CONTENT : 'Dashboard content',
    EXPENSE_ENTRY_WIDGET : 'Expense entry widget',
    ADD_NEW_WIDGET : 'Add New Widget',
    ADD_WIDGET : 'Add Widget',
    REMOVE_WIDGET : 'Remove Widget',
    EXPENSE_ENTRY_CARD_DISCRIPTION : "Start a new expense entry or timer for work you need to track or post to Billing.",
    SELECT_A_WIDGET_TO_ADD_TO_YOUR_DASHBOARD :"Select a widget to add to your dashboard.",
    EXPENSE_ENTRY_GRID_DESCRIPTION : "View and work with expense entries in a grid format.",
    RECENT_CLIENT : "My Recent Client Matters",
    RECENT_CLIENT_DESCRIPTION : "Start a new expense entry from a list of the most recent client matters you have submitted expense for.",
    EXPENSE_ENTRY_POSTED : "Expense entry posted.",
    EXPENSE_ENTRY_SAVED : "Expense entry saved.",
    EXPENSE_ENTRY_CANT_POSTED : "Expense entry could not be posted.",
    EXPENSE_ENTRY_CANT_SAVED : "Expense entry could not be saved.",
    SOMETHING_WENT_WRONG : "Something went wrong.",
    START : "Start",
    STOP : "Stop",
    RESET : "Reset",
    CLEAR_ENTRY : "Clear Entry",
    // Actions buttons
  
    DELETE : 'Delete',
    DELETE_ALL : 'Delete All',
    POST : 'Post',
    POST_ALL : 'Post All',
    SAVE_ENTRY : 'Save Entry',
    CLOSE : 'Close',
  
    // Dialog Components
  
    ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_EXPENSE_ENTRY : "Are you sure you want to discard this expense entry?",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_EXPENSE_ENTRY : "Are you sure you want to delete this expense entry?",
    DO_YOU_WANT_DO_SAVE_CURRENT_EXPENSE_ENTRY : "Do you want to save the current expense entry before removing this widget?",
    SAVE_CURRENT_EXPENSE_ENTRY : "Save Current Expense Entry?",
    SAVE_CHANGES_ : "Save Changes?",
    SAVE_CHANGES : "Save Changes",
    DO_YOU_WANT_TO_SAVE_EXPENSE_ENTRY : "Do you want to save the changes you made to this expense entry?",
    DISCARD : "Discard",
    DISCARD_ENTRY : "Discard Entry",
    DISCARD_CHANGES : "Discard Changes",
    DISCARD_EXPENSE_ENTRY : "Discard Expense Entry?",
    DELETE_EXPENSE_ENTRY : "Delete Expense Entry?",
    TWO_ACTIONS : "Two Actions",
    THREE_ACTIONS : "Three Actions",
    // Dashboard Tabs
    EXPENSE_ENTRY_DASHBOARD_TAB: "Expense Entry Dashboard",
    TODAYS_WORK_DASHBOARD_TAB: "Todays Work Dashboard",
    CLIENT_REPORT_DASHBOARD_TAB: "client Report Dashboard",
    ADD_NEW_DASHBOARD_TAB: "add New Dashboard",
  }
  