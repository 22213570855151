import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const StyledButton = styled(LoadingButton)`
  text-transform: none;
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 20px;
  height: 34px;
  border-radius: 4px;
`;

export default StyledButton;
