import React, { memo, useState, useRef } from "react" 
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog'; 
import DialogActions from '@mui/material/DialogActions'; 
import DialogContent from '@mui/material/DialogContent'; 
import DialogContentText from '@mui/material/DialogContentText'; 
import DialogTitle from '@mui/material/DialogTitle'; 
import AddExpenseEntry from './AddExpenseEntry' 
 
export default function ExpenseEntryModal({ open,handleClose,selectedExpenseEntry,isOpenFromModel,GetTimeEntryList}) { 
 
  return ( 
    <div> 
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description" 
        maxWidth={"md"} 
      > 
        <DialogContent style={{padding:0}}> 
            <AddExpenseEntry handleClose={handleClose} selectedExpenseEntry={selectedExpenseEntry} isOpenFromModel={isOpenFromModel} GetTimeEntryList={GetTimeEntryList}/> 
        </DialogContent> 
      </Dialog> 
    </div> 
  ); 
}