import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CssTextField = styled(TextField)((props) => ({
  "& .MuiOutlinedInput-root": {
    position: "relative", // Add this to position the before element
    "&::before": {
      // Add this to style the before element
      content: `'${"$"}'`, // Add the sign to the content
      position: "absolute",
      left: "20px", // Add padding on the left to position the sign
      top: "0%", // Center the sign vertically
      transform: "translateY(-50%)", // Center the sign vertically
      color: "red",
    },
  },
  "& .Mui-disabled": {
    // backgroundColor: "rgba(140, 210, 250, 0.3)",
    color: "black",
  },
  "& input.Mui-disabled": {
    color: "green",
  },
  "& label.Mui-focused": {
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
    top: "0px",
  },
  "& label.Mui-disabled": {
    backgroundColor: "transparent",
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
  },
  "& span.MuiInputLabel-asterisk": {
    color: "#FF9800",
  },
  "& .MuiInputLabel-root": {
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
    backgroundColor: "#8CD2FA4D",
  },
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiFormHelperText-root.Mui-disabled": {
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: "8px",
      borderColor: props.color === "primary" ? "#1976d2" : "#9C27B0",
    },
    "&.Mui-focused fieldset": {
      borderColor: props.color === "primary" ? "#1976d2" : "#9C27B0",
      borderWidth: "1px",
    },
    "&.Mui-disabled fieldset": {
      borderColor: props.color === "primary" ? "#1976d2" : "#9C27B0",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "#fff",
    },
    "&:input.Mui-disabled": {
      color: "green",
    },
  },
}));

export default CssTextField;
