import AddExpenseEntry from "./AddExpenseEntry";
import ExpenseEntryDataGrid from "./ExpenseEntryDataGrid";
import ExpenseEntryModal from "./ExpenseEntryModal";
import React, { useEffect, useState } from 'react';
import { useQuery } from "@apollo/client";
import { LOAD_ALL_ACTIVITY_EXPENSE } from '../../GraphQL/Queries';
import { Box } from '@mui/material';

export default function ExpenseEntryContainer(props) {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const {error, loading, data, refetch} = useQuery(LOAD_ALL_ACTIVITY_EXPENSE);
  const [selectedExpenseEntry, setSelectedExpenseEntry] = React.useState({});

    useEffect(() => {
      if(data){
          setRows(data.getAllActivityExpenses)
          setIsLoading(false)
      }
    }, [data])

    const GetExpenseEntryList = () => {
      setIsLoading(true)
      refetch()
    }

    const handleClose = () => {
      setOpen(false);
    };

    return (

      <div>
        <Box style={{ marginLeft: "20px", marginTop: "20px" }}><AddExpenseEntry GetExpenseEntryList={GetExpenseEntryList} isOpenFromModel={false} /></Box>
        <ExpenseEntryDataGrid GetExpenseEntryList={GetExpenseEntryList} rows={rows} setOpen={setOpen} setSelectedExpenseEntry={setSelectedExpenseEntry} isLoading={isLoading}/>
        <ExpenseEntryModal open={open} isOpenFromModel={true} selectedExpenseEntry={selectedExpenseEntry} handleClose={handleClose} />
      </div>
    );
  }