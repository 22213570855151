import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CssMultiTextField = styled(TextField)((props) => ({
  "& label.Mui-focused": {
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
    top: 0,
  },
  "& span.MuiInputLabel-asterisk": {
    color: "#FF9800",
  },
  "& .MuiInputLabel-root": {
    // top: '-9px',
    color: props.color === "primary" ? "#1976d2" : "#9C27B0",
  },
  "& .MuiTextField-root": {
    // top: '-9px',
    width: "100%",
  },
  "& .MuiOutlinedInput-root": {
    // height: '34px',
    borderRadius: "6px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: "8px",
      borderColor: props.color === "primary" ? "#1976d2" : "#9C27B0",
    },
    "&.Mui-focused fieldset": {
      borderColor: props.color === "primary" ? "#1976d2" : "#9C27B0",
      borderWidth: "1px",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "#fff",
    },
  },
}));

export default CssMultiTextField;
