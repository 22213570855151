import React, {useEffect, useState} from 'react';
import {
    Grid,
    Stack,
    FormControl,
    FormControlLabel,
    Switch,
    IconButton,
    Box,
    Typography
} from '@mui/material';
import {
    GridRowModes,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('b5aaed8b75e9139e4d22473261d34883Tz02NDgwNCxFPTE3MTM1MzkwODg2MzAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import "./ExpenseEntryDataGrid.scss"
import expenseEntryService from "../../GraphQL/Services.js";
import CustomPaginationTimeEntryGrid from '../../components/CustomPagination/CustomPaginationExpenseEntryGrid';
import AlertDialog from "../../components/common/AlertDialog"
import { literals } from "../../enums/literalCodes";

function EditToolbar(props) {
    const { setRows, setRowModesModel, rowSelectionModel, rows, handleCloseTimeEntryDataGrid, postMultipleRows } = props;

    return (

        <GridToolbarContainer className="toolbar-container">
          <Typography className="toolbar-title">
            {literals.EXPENSE_ENTRY_GRID}
          </Typography>

          <div className='toolbar-button-group'>
            <GridToolbarFilterButton
              className='filter-button'
              componentsProps={{
                button: {
                  startIcon: (
                    //<IconButton >
                        <SearchIcon style={{ fontSize: '1.5rem' }} />
                    //</IconButton>
                  ),
                },
              }}
            />
              <IconButton>
                <Tooltip title="Settings">
                  <SettingsIcon />
                </Tooltip>
              </IconButton>
              <Tooltip title={literals.REMOVE_WIDGET}>
                <IconButton 
                // onClick={() => handleCloseTimeEntryDataGrid()}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
          </div>
        </GridToolbarContainer>
  
    );
  }

EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func,
    setRows: PropTypes.func,
    handleCloseTimeEntryDataGrid: PropTypes.func,
    rowSelectionModel: PropTypes.array,
    rows: PropTypes.array,
};

export default function ExpenseEntryDataGrid({rows, setOpen, setSelectedExpenseEntry, isLoading, GetExpenseEntryList}) {
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [Modal, setModal] = useState(null)

    const columns = [
        {
            field: 'clientName',
            headerName: 'Client',
            type: 'string',
            // editable: true,
            width: 240,
            valueGetter: params => `${params.row.clientEntity.code} - ${params.row.clientEntity.description}`,
        },
        {
            field: 'matterName',
            headerName: 'Matter',
            type: 'string',
            width: 200,
            // editable: true,
            valueGetter: params => `${params.row.customerEntity.code} - ${params.row.customerEntity.description}`,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 140,
            editable: false,
            renderCell: (params) => ( <div>
                {
                    // rowModesModel[params.row.id]?.mode === GridRowModes.Edit ? 
                    // <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //     <UnStyledDatePicker
                    //         components={{
                    //             OpenPickerIcon: getCalendarIcon
                    //         }}
                            
                    //         value={params.row.date}
                    //         onChange={(date) => handleDate(params.row,date)}
                    //         name="date"
                    //         InputLabelProps={{ shrink: true }}
                    //         size="small"
                    //         inputFormat="MM/DD/YYYY"
                    //         color="primary" 
                    //         format="MM/DD/YYYY"                    
                    //         />
                            
                    // </LocalizationProvider> :
                    <span>{ dayjs(params.row.date).format('MM/DD/YYYY')}</span>
                }
            </div>
                
            ),
        },
        {
            field: 'expense',
            headerName: 'Expense',
            type: 'string',
            width: 150,
            editable: false,
            valueGetter: params => `${params.row.activityExpense.expenseCode.code} - ${params.row.activityExpense.expenseCode.description}`,
    
            // renderCell: (params) => {
    
            //     const isDisabledForPosting = params.row.status === literals.POSTED ? true : false;
            //     return (<input disabled={isDisabledForPosting} value={params.row.hours} onChange={(val) => handleHours(val, params)} />)
            // }
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 90,
            editable: false,
            valueGetter: params => params.row.activity.quantity
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 90,
            editable: false,
            valueGetter: params => `$${params.row.activity.amount}`
        },
        {
            field: 'narrative',
            headerName: 'Narrative',
            type: 'string',
            width: 150,
            // editable: true,
            valueGetter: params => params.row.activityExpense.description
        },
        {
            field: 'attachment',
            headerName: 'Attachment',
            type: 'string',
            width: 150,
            // editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 70,
            editable: false,
            valueGetter: params => params.row.activityStatus.code
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 140,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const RowDetail = rows.find(row => row.id === id);
                const isDisabledForPosting = false;
    
                // if (isInEditMode && !isDisabledForPosting) {
                //     return [
                //         <StyledButton style={{ width: "50px", height: "30px" }} 
                //             // onClick={handleonSubmit(id, literals.SAVE)}
                //             variant="outlined">
                //                 {/* {literals.SAVE} */}
                //                 Save
                //         </StyledButton>
                //         ,
                //         <StyledButton style={{ width: "50px", height: "30px" }} 
                //             // onClick={handleonSubmit(id, literals.POST)} 
                //             variant="contained">
                //                 {/* {literals.POST} */}
                //                 POST
                //         </StyledButton>
                //         ,
                //     ];
                // }
                if (!isDisabledForPosting) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Edit" color='primary'><EditIcon /></Tooltip>}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id, RowDetail)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Delete" color='primary'><DeleteIcon /></Tooltip>}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [<div></div>];
            },
        },
    ];    


    const handleEditClick = (id, row) => () => {
        setOpen(true);
        setSelectedExpenseEntry(row)
    }; 

    const handleonSubmit = (id, action) => () => {
        isSubmit = action
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
      setModal({
          open: true,
          title: literals.DELETE_EXPENSE_ENTRY,
          message: literals.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_EXPENSE_ENTRY,
          cancelLabel: literals.GO_BACK,
          discardLabel: literals.DELETE,
          confirmLabel: "",
          rowId: id,
          onCancel: closeModal,
          onDiscard: deleteExpenseEntry,
          ModalType: literals.TWO_ACTIONS
      });
      // setDeleteRowId(id)
      // setRows(rows.filter((row) => row.id !== id));
  };

    const closeModal = () => {
      setModal(null);
    }

    const deleteExpenseEntry = (rowId) => {
        expenseEntryService.deleteExpenseEntry(rowId)
        .then(res => {
            if(res?.data?.deleteActivityExpense){
                closeModal();
                GetExpenseEntryList();
            }
        })
        .catch(e => {
            console.log(e)
        });
    }
    


    return (
        <>
            <Box
                sx={{
                    height: 300,
                    width: '97%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
                style={{marginLeft: "20px", marginTop: "20px"}}
                >
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    disableColumnMenu={true} // to disable column menu
                    checkboxSelection // enable checkbox selection
                    disableRowSelectionOnClick // disable row selection on click
                    onRowClick={(params, event) => {
                        // if (editRowId !== params.id && params.row.status !== literals.POSTED) {
                            // If row is not already in edit mode
                            // handleRowEditStart(params);
                        // }
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        // setRowSelectionModel(newRowSelectionModel);
                    }}

                    localeText={{
                        // to remove/hide the default text of filter Icon
                        toolbarFilters: "",
                    }}
                    slots={{
                        //custom Toolbar
                        toolbar: EditToolbar,
                        loadingOverlay: LinearProgress,
                        pagination: CustomPaginationTimeEntryGrid
                    }}
                    loading={isLoading}
                    pagination={true}
                    initialState={{
                        pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                        },
                    }}
                />
            </Box>

            {Modal !== null && <AlertDialog {...Modal} />}
            
        </>
    )
}
